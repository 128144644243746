import React from 'react'

const Loader = () => {
  return (
    <div>
        <div className='loader_out'>
        <img src="assets/images/logo-final.png" alt="logo" />
        </div>
    </div>
  )
}

export default Loader