import React from 'react';
// import Freeshiping from '../Home/Freeshiping';
// import Follow from '../Home/Follow';


const Footer = () => {
  return (
    <>
{/* <Follow /> */}
   
    <footer className="footer-part">
    <div className="container">
        <div className="row">
            <div className="col-sm-6 col-xl-3">
                <div className="footer-widget">
                    <a className="footer-logo" href="#"><img src="/assets/images/logo-final.png" alt="logo" /></a>
                    <p className="footer-desc">Adipisci asperiores ipsum ipsa repellat consequatur repudiandae quisquam assumenda dolor perspiciatis sit ipsum dolor amet.</p>
                    <ul className="footer-social">
                        <li>
                            <a className="icofont-facebook" href="#"></a>
                        </li>
                      
                        <li>
                            <a className="icofont-instagram" href="#"></a>
                        </li>
                       
                    </ul>
                </div>
            </div>
            <div className="col-sm-6 col-xl-3">
                <div className="footer-widget">
                    <h3 className="footer-title">quick Links</h3>
                    <div className="footer-links">
                        <ul>
                            <li><a href="#">My Account</a></li>
                            <li><a href="#">Shop</a></li>
                            <li><a href="#">Best Seller</a></li>
                            <li><a href="#">New Arrivals</a></li>
                        </ul>
                        <ul>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/contact">Contact</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Terms and Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-xl-3">
                <div className="footer-widget contact">
                    <h3 className="footer-title">contact us</h3>
                    <ul className="footer-contact">
                        <li><i className="icofont-ui-email"></i>
                            <p><span>support@herbal.com</span></p>
                        </li>
                        <li><i className="icofont-ui-touch-phone"></i>
                            <p><span>+120 279 532 13</span><span>+120 279 532 14</span></p>
                        </li>
                        <li><i className="icofont-location-pin"></i>
                            <p>1Hd- 50, 010 Avenue, NY 90001 United States</p>
                        </li>
                    </ul>
                </div>
            </div>
           
            <div className="col-sm-6 col-xl-3">
                <div className="footer-widget">
                    {/* <h3 className="footer-title">Download App</h3>
                    <p className="footer-desc">Lorem ipsum dolor sit amet tenetur dignissimos ipsum eligendi autem obcaecati minus ducimus totam reprehenderit exercitationem!</p>
                    <div className="footer-app">
                        <a href="#"><img src="/assets/images/google-store.png" alt="google" /></a>
                        <a href="#"><img src="/assets/images/app-store.png" alt="app" /></a>
                    </div> */}

<h3 className='mb-2'>100% secure payments by EaseBuzz</h3>
<h5>Our Delivery partners:</h5>
Delhivery | XpressBees | eComXpress | DTDC
<div className="del-dt">
                        <a href="#"><img src="/assets/images/dtdc.jpg" alt="payment" /></a>
                        <a href="#"><img src="/assets/images/delivery.jpg" alt="payment" /></a>
                        <a href="#"><img src="/assets/images/xpress.jpg" alt="payment" /></a>
                        <a href="#"><img src="/assets/images/express.png" alt="payment" /></a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div className="row">
       <div className='footr-top'>
            <div className="col-12">
            <div className="container">
                <div className="footer-bottom">
                    <p className="footer-copytext">© 2024 VyraHerbals, Inc. All rights reserved.</p>
                    <div className="footer-card">
                        <a href="#"><img src="/assets/images/payment/jpg/01.jpg" alt="payment" /></a>
                        <a href="#"><img src="/assets/images/payment/jpg/02.jpg" alt="payment" /></a>
                        <a href="#"><img src="/assets/images/payment/jpg/03.jpg" alt="payment" /></a>
                        <a href="#"><img src="/assets/images/payment/jpg/04.jpg" alt="payment" /></a>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>

        <div class="footer_wts"><a href="#" target="_blank" rel="noopener"><img class="whts-icon" src="/assets/images/wts-nw.png" alt="" width="126" height="127" /></a></div>
</footer>
</>
  )
}

export default Footer