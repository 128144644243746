import React ,{useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
// import { FaBars } from "react-icons/fa6";
import { FaBarsStaggered } from "react-icons/fa6";
import { useCart } from '../Contexts/CartContext';


const Header = () => {
    const [show, setShow] = useState(false);
    const { cartCount } = useCart();
    const [searchVisible, setSearchVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleSearch = () => {
        if (searchTerm.trim()) {
            navigate(`/search/${searchTerm}`);
            setSearchTerm('');
        }
        setSearchVisible(false);
    };

  return (
    <>
    <div className="backdrop"></div>
    {/* <section className="header-top">
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="header-top-welcome text-center">
                        <p>Welcome to Vyra Herbals in Your Dream Online Store!</p>
                    </div>
                </div>
                <div className="col-md-5 col-lg-3">
                    <div className="header-top-select">
                    </div>
                </div>
            </div>
        </div>
    </section> */}

    <section className="header-part">
        <div className="container">
            <div className="header-content">
                <div className="header-media-group">
                <button  className="toggle" onClick={()=>setShow(true)}>

                <FaBarsStaggered />

             </button>
                </div>
                <Link to="/" className="header-logo">
               <img src="/assets/images/logo-final.png" alt="logo" /></Link>
               <ul className="navbar-list">
                            <li className="navbar-item"><Link className="navbar-link" to="/">home</Link>
                               
                            </li>
                            <li className="navbar-item"><a className="navbar-link" href="/category/hair-oil">Hair-Oil</a>
                              
                            </li>
                            <li className="navbar-item"><a className="navbar-link" href="/category/shampoo">Shampoo</a>
                             
                            </li>
                            <li className="navbar-item"><a className="navbar-link" href="/category/combs">Combs</a>
                                
                            </li>
                            <li className="navbar-item"><a className="navbar-link" href="/category/kits">Kits</a>
                                
                            </li>
                            
                            <li className="navbar-item"><a className="navbar-link  dropdown-arrow" href="/search/all">All-Products</a>
                                {/* <ul className="dropdown-position-list">
                                    <li><a href="blog-grid.html">blog grid</a></li>
                                    <li><a href="blog-standard.html">blog standard</a></li>
                                    <li><a href="blog-details.html">blog details</a></li>
                                </ul> */}
                            </li>
                            
                        </ul>
                <div className="header-widget-group">
                    {/* <a href="compare.html" className="header-widget" title="Compare List"><i className="fas fa-random"></i><sup>0</sup></a>  */}
                    {/* <button className="header-widget header-cart"
                        title="Cartlist"><i className="fas fa-search"></i></button> */}

                        {!searchVisible && ( <button className="header-widget header-search" title="Search" onClick={() => setSearchVisible(!searchVisible)}>
                            <i className="fas fa-search"></i>
                        </button>)}
                        {searchVisible && (
                            <div className="search-bar">
                                <input 
                                    type="text" 
                                    placeholder="Search..." 
                                    value={searchTerm} 
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && searchVisible) {
                                            handleSearch();
                                        }
                                    }}
                                />
                                <button onClick={handleSearch}><i className="fas fa-search"></i></button>
                            </div>
                        )}

                        {/* <a href="#" className="header-widget header-wishlist" title="Wishlist"><i className="fas fa-heart"></i><sup>0</sup></a> */}
                        
                          <button className="header-widget header-cart" title="Cartlist">
                          <a href="/cart">
                            <i className="fas fa-shopping-basket"></i>
                          </a>
                          <sup>{cartCount}</sup></button> 
                        
                  </div>
            </div>
        </div>
    </section>

 
    <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
       <Offcanvas.Header closeButton></Offcanvas.Header>
       <Offcanvas.Body>
         <div className='mobile-menuu'>
                <ul className="header-top-list">
                    <li><a href="/">Home</a></li>
                    <li><a href="/category/hair-oil">Hair-Oil</a></li>
                    <li><a href="/category/shampoo">Shampoo</a></li>
                    <li><a href="/category/combs">Combs</a></li>
                    <li><a href="/category/kits">Kits</a></li>
                    <li><a href="/search/all">All-Products</a></li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/contact">Contact us</a></li>
                </ul>
         </div>
       </Offcanvas.Body>
     </Offcanvas>
    
  </>
  )
}

export default Header